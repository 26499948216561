<template>
  <section class="chat-app-window my-1 mx-2" 
    :style="isFromGlobalChat ? 'height: calc(100% - 102px - 3rem); min-height: 35vh' : 'height: calc(100% - 142px - 4.75rem)'">

    <!-- User Chat Area -->
    <app-scroll 
      ref="refChatLogPS" 
      class="user-chats scroll-area"
      :options="{ scrollPanel: { scrollingX: false } }"
    >
      <template>
        <!-- Loading messages... -->
        <div v-if="isLoading" class="centered-message w-100">
          <b-spinner />
        </div>
        <!-- Empty chat -->
        <div 
          v-else-if="isChatEmpty" 
          class="d-flex placeholder align-item-center justify-content-center flex-column chat-empty-placeholder"
        >
          <b-img :src="placeholderImage" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{ $t('chat.user-chat.empty-message') }}
            </p>
          </div>
        </div>
        <!-- Full Chat -->
        <chat-log 
          v-else 
          :room="room" 
          :chat-data="chatData" 
        />
      </template>
    </app-scroll>

    <!-- Message Input -->
    <b-form 
      class="chat-app-form d-flex mt-75" 
      @submit.prevent="sendMessage"
    >
      <b-input-group class="input-group-merge form-send-message mr-1" style="width: calc(100% - 100px);">
        <plain-editor
          id="plainEditorInput"
          v-model="chatInputMessage"
          class="form-control text-editor"
          :placeholder="$t('message.content')"
          :disabled="isDisabled"
          :is-chat-v2="true"
          @input="keyPressInTextInput()"
          @submit="submit"
        />
      </b-input-group>
      <b-button 
        variant="primary" 
        type="submit" 
        :disabled="isDisabled"
        style="width: 100px; height: 38px;"
      >
        {{ $t('send.button') }}
      </b-button>
    </b-form>

    <div class="chat-area-footer">
      <b-img :src="backgroundPlaceholder" class="chat-footer-img"/>
    </div>
  </section>
</template>

<script>
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import ChatPlaceholder from '@/assets/images/placeholders/light/chat.svg';
import { CHAT_GETTERS } from '@/views/apps/chat/constants/chat-store-constants';
import ChatLog from './ChatLog.vue';
import moment from "moment";
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';

export default {
  name: 'ChatComponent',
  components: {
    PlainEditor,
    ChatLog,
    AppScroll,
  },
  props: {
    room: {
      type: String,
      default: null,
    },
    isFromGlobalChat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyMessage: '',
      chatInputMessage: '',
      isDisabled: false,
      // The name of the room with the roomType included
      isLoading: false,
      lastMsgTms: null
    };
  },
  computed: {
    toUser() {
      return this.$store.getters[CHAT_GETTERS.individualChatUser];
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    placeholderImage() {
      return ChatPlaceholder;
    },
    chatData() {
      if (this.$store.getters.chats[this.room] != null) {
        return this.$store.getters.chats[this.room].unpaginated;
      }
      return [];
    },
    roomChatMessagesNum() {
      return this.chatData.length;
    },
    isChatEmpty() {
      return this.roomChatMessagesNum === 0;
    },
    lastMessage() {
      if(!this.isChatEmpty){
        const index = this.roomChatMessagesNum - 1;
        return this.chatData[index];
      }
      return null;
    },
    lastMessageTimestamp() {
      return this.lastMessage ? this.lastMessage.date : null;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
  },
  sockets: {
    async received(data) {
      await this.$store.dispatch('setItemSocket', {
        itemType: 'chats',
        storedKey: this.room == null ? this.$store.getters.currentCollective.slug : this.room,
        item: data,
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
  async created() {
    this.$socket.emit(
      'join',                                       //action
      this.$store.getters.currentCollective.slug,   //space where joining
      this.$store.getters.loggedMember.key,         //member who joins
      this.room                                     //room to join
    );
    await this.fetchData();
    await this.$nextTick();
    this.scrollToBottom();
    this.lastMsgTms = this.lastMessageTimestamp;
    // console.log('this.lastMsgTms', this.lastMsgTms);
  },
  watch: {
    lastMessageTimestamp() {
      this.lastMsgTms = this.lastMessageTimestamp;
    }
  },
  methods: {
    submit() {
      this.sendMessage();
    },
    // sendMessage() {
    //   let chatInputMessageWithoutSpaces = this.chatInputMessage;

    //   const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
    //   wordsToReplace.forEach((word) => {
    //     chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
    //   });

    //   if (!chatInputMessageWithoutSpaces.length > 0) {
    //     return;
    //   }
    //   //Save message
    //   this.$socket.emit('message', {
    //     communitySlug: this.$store.getters.currentCollective.slug,
    //     msg: this.chatInputMessage,
    //     receiverMemberKey: this.toUser?.userKey,
    //     memberKey: this.loggedMember.key,
    //     room: this.room,
    //   });
    //   this.chatInputMessage = '';
    // },
    keyPressInTextInput() {
      // console.log('Typing...');
      const plainEditorInputElem = document.getElementById('plainEditorInput');
      // console.log('this.chatInputMessage', this.chatInputMessage);
      const messageLength = this.chatInputMessage?.length - 7; // 7 de <p></p>
      const lineMaxChars = 60;
      const fisrtLineMaxChars = 40;
      // console.log(`the message is ${messageLength} chars length`);
      if(messageLength > fisrtLineMaxChars){
        const lineHeight = 40;
        // console.log('We need to increase the textarea...');
        const linesNum = messageLength / lineMaxChars;
        // console.log(`The message has ${linesNum} lines`);
        const inputHeight = linesNum * lineHeight;
        // console.log(`So we will make the height to be ${inputHeight}px`);
        plainEditorInputElem.style.height = Math.max(45, inputHeight)+"px";
      }
    },
    sendMessage() {
      let chatInputMessageWithoutSpaces = this.chatInputMessage;
      const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
      wordsToReplace.forEach((word) => {
        chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
      });
      //Don't send empty messages
      if (chatInputMessageWithoutSpaces.length == 0) {
        return;
      }
      let lastMsgOld = false;
      // console.log('this.lastMsgTms', this.lastMsgTms);
      if(this.lastMsgTms){
        // const lastMsgDateStr = this.getMessageDateTime(this.lastMsgTms);
        // console.log('last message was on:', lastMsgDateStr);
        // var CurrentDate = moment();
        // console.log('CurrentDate', CurrentDate);
        // var last = moment(lastMsgDateStr, 'YYYY-MM-DDTHH:mm:ss.SSSSZ'); //last date
        var last = moment(new Date(this.lastMsgTms)); //last date
        // console.log('last', last);
        var now = moment(new Date()); //todays date
        // console.log('now', now);
        // var datesDifference = moment.duration(now.diff(last));
        // console.log('datesDifference', datesDifference);
        // var minutesSince = datesDifference.minutes();
        // console.log('minutesSince', minutesSince);

        //timestamps
        var lastUnixMS = last.unix() * 1000; //todays date
        //console.log('lastUnixMS', lastUnixMS);
        var nowUnixMS = now.unix() * 1000; //todays date
        //console.log('nowUnixMS', nowUnixMS);
        const diffNowLastUnixMS = nowUnixMS - lastUnixMS;
        //console.log('diffNowLastUnixMS', diffNowLastUnixMS);  //milliseconds

        const diffUnixMS = nowUnixMS - this.lastMsgTms;
        //console.log('diffUnixMS', diffUnixMS);  //milliseconds
        const diffMin = diffUnixMS / 60000;
        //console.log('diffMin', diffMin);  //minutes

        // Save new last message
        this.lastMsgTms = nowUnixMS;

        lastMsgOld = diffMin >= 10;
        //console.log('last message is older than 10min?', lastMsgOld);
      }
      //Save message
      const saveMessageData = {
        communitySlug: this.$store.getters.currentCollective.slug,
        msg: this.chatInputMessage,
        memberKey: this.loggedMember.key,
        receiverMemberKey: this.toUser?.userKey,
        lastMsgOld: lastMsgOld,
        room: this.room
      };
      //console.log('saveMessageData', saveMessageData);

      // Send message
      this.$socket.emit('message', saveMessageData);

      //reset message
      this.chatInputMessage = '';
      // reset input height
      document.getElementById('plainEditorInput').style.height = '45px';
    },
    getMessageDateTime(timestamp) {
      return moment(timestamp).toISOString();
    },
    async fetchData() {
      //Get messages
      await this.$store.dispatch('getItemsSocket', {
        itemType: 'chats',
        storedKey: this.room == null ? this.$store.getters.currentCollective.slug : this.room,
        perPage: 100,
        requestConfig: {
          room: this.room,
        },
      });
    },
    offsetHeight() {
      return this.$refs.refChatLogPS.getOffsetHeight();
    },
    scrollHeight() {
      return this.$refs.refChatLogPS?.getScrollHeight();
    },
    scrollTop() {
      return this.$refs.refChatLogPS.getPosition().scrollTop;
    },
    /**
     * Scrolls to `scrollBottom` pixels from the bottom of the container.
     * @param {number} scrollBottom Defaults to 0.
     */
    scrollToBottom(scrollBottom = 0) {
      if (this.$refs.refChatLogPS) {
        this.$refs.refChatLogPS.scrollToBottom(scrollBottom, 0);
      }
    },
  },
};
</script>
<style scoped>
.placeholder-img {
  margin-top: 5rem;
  width: 120px;
  display: block;
}
.chat-empty-placeholder {
  height: 100% !important;
}
.chat-app-form {
  border: none;
}
.chat-area-footer {
  /* background-color: #ecf7f7; */
  border-top: 1px solid #efefef;
  height: 6rem;
  margin: 1rem -1.5rem 0;
}
.chat-footer-img {
  opacity: 0.3;
  width: 100%;
  height: 100%;
}

.chat-app-window {
  margin-top: 0;
}
</style>
